import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"

import header from '../scss/header.module.scss'

import Logo from '../images/lgo-stand-horiz.inline.svg'

if (typeof window !== 'undefined') {
  // it's safe to use window now
  let last_known_scroll_position = 0;
  let ticking = false;

  function ickySticky() {
    const content = document.querySelector('#content');
    if (content != null) {
      if (last_known_scroll_position > 200){
        content.classList.add('sticky');
      }
      else {
        content.classList.remove('sticky');
      }
    }
  }

  document.addEventListener('scroll', function(e) {
    last_known_scroll_position = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(function() {
        ickySticky(last_known_scroll_position);
        ticking = false;
      });

      ticking = true;
    }
  });
}


export default function Header(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          standlogohoriz: file(relativePath: { eq: "lgo-stand-horiz.png" }) {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          site {
            siteMetadata {
              title
              description
              author
            }
          }
        }
      `}
      render={data => (
        <>
        <header className={header.header}>
          <nav className={header.nav}>
            <ul>
              <li className={header.logo}>
                <Link id="headerlogolink" to="/" className={header.logoLink} title={data.site.siteMetadata.title} >
                  <Logo id="headerlogo" className={header.logoImage} />
                </Link>
              </li>
              <li className={header.buttons}>
                <Link id="headerbacklink" to="/" title={data.site.siteMetadata.title} className="button secondary">Back</Link>
                <button className="cta__header__standwithus button primary" data-modal-open="standwithus">Stand with us</button>
              </li>
            </ul>
          </nav>

          {/* if no headerText, don't show this */}
          {props.headerText &&  <h1 className={header.h1}>{props.headerText}</h1>}

        </header>
        </>
      )}
    />
  )
}


