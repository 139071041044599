import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
// import SEO from "../components/seo"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

import single from '../scss/single.module.scss'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <>
    <SEO slug={data.markdownRemark.frontmatter.slug} title={`${data.markdownRemark.frontmatter.title} | ${data.site.siteMetadata.title}`} />
    <Helmet bodyAttributes={{id: 'top',class: 'single'}}  />
    <div className="wrapper">

      <div className={single.wrapper} id="content">
        <Header />
        <div className={single.content}>
          <h1>{frontmatter.title}</h1>
          <div
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>

      <footer className={single.footer}>
        <Footer />
      </footer>
    </div>
    </>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`